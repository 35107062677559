import React, { useState } from 'react'

import pick from 'lodash/pick'
import { useMutation, useQuery } from 'react-query'
import { useSetState } from 'react-use'

import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { CheckBox, Label, Select, TextArea } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import { useCurrentUser, useTeam, useTeamSlug, useUpdateTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import team from 'utils/api/team'
import { DEFAULT_CANDIDATE_ONBOARDING_MESSAGE } from 'utils/constants/onboarding'
import { Team } from 'utils/types'

const AdminTalentSetting = () => {
  const teamSlug = useTeamSlug()
  const { canManageCompany } = useAccessControl(teamSlug)
  const { user, reloadUser } = useCurrentUser()
  const { team } = useTeam(teamSlug)
  const { updateTeamAsync, isUpdatingTeam } = useUpdateTeam(teamSlug)
  const [updatedTeam, setUpdatedTeam] = useSetState(team)
  const [allowSendAsForIntros, setAllowSendAsForIntros] = useState(user.allow_send_as_for_intros)

  const { data: talentsListData, isLoading: loadingTalentsList } = useQuery(
    ['getTalentLists'],
    () => callApi(api.getTalentLists, teamSlug),
  )
  const talentsList = talentsListData?.company_lists.filter((list) => !list.is_all_candidates) || []

  const handleTalentSubmit = () => {
    updateUser()
    saveAllTalentSettings()
    cabalToast({ style: 'success', content: 'Successfully updated' })
  }

  const { mutate: updateUser } = useMutation(
    ['updateUser'],
    () => callApi(api.updateCurrentProfile, { allow_send_as_for_intros: allowSendAsForIntros }),
    {
      onSuccess: () => {
        reloadUser()
      },
    },
  )

  const saveAllTalentSettings = () => {
    updateTeamAsync(
      pick<Team, keyof Team>(updatedTeam, [
        'talent_coordinator',
        'talent_onboarding_message',
        'default_talent_list_id',
      ]),
    )
  }

  return (
    <Widget title="Talent">
      <div className="flex flex-col gap-4">
        <CheckBox
          checked={allowSendAsForIntros}
          onChange={(e) => setAllowSendAsForIntros(e.currentTarget.checked)}
          label={`Allow Cabal to send talent introductions on my behalf`}
        />
        {canManageCompany && (
          <>
            <ModalInputWrapper>
              <ModalInputLabel>Talent Coordinator</ModalInputLabel>
              <Select<string>
                className="mb-3 w-full"
                options={
                  team?.admins_and_members.map((f) => ({
                    label: f.name,
                    value: f.uuid,
                  })) || []
                }
                value={updatedTeam.talent_coordinator || ''}
                onChange={(u) => setUpdatedTeam({ talent_coordinator: u })}
              />
            </ModalInputWrapper>
            <ModalInputWrapper>
              <ModalInputLabel>Candidate welcome message</ModalInputLabel>
              <TextArea
                value={updatedTeam.talent_onboarding_message}
                placeholder={DEFAULT_CANDIDATE_ONBOARDING_MESSAGE}
                rows={5}
                className="w-full"
                onChange={(e) => setUpdatedTeam({ talent_onboarding_message: e.target.value })}
              />
            </ModalInputWrapper>

            <ModalInputWrapper>
              <ModalInputLabel>Candidate default list</ModalInputLabel>
              <Select<string>
                className="mb-3 w-full"
                placeholder="Leave it blank to let the system choose for you"
                options={
                  talentsList
                    ? talentsList.map((talentList) => ({
                        value: talentList.uuid,
                        label: talentList.name,
                      }))
                    : []
                }
                value={
                  loadingTalentsList ? undefined : updatedTeam.default_talent_list_id || undefined
                }
                onChange={(talentList) => setUpdatedTeam({ default_talent_list_id: talentList })}
              />
            </ModalInputWrapper>

            <div className="flex justify-end mt-2">
              <CabalButton
                variant="primary"
                disabled={isUpdatingTeam}
                working={isUpdatingTeam}
                onClick={handleTalentSubmit}
                data-testid="save-public-dir"
              >
                Save
              </CabalButton>
            </div>
          </>
        )}
      </div>
    </Widget>
  )
}

export default AdminTalentSetting
